<template>
  <div class="agent-list">
    <h2>代理列表</h2>
  </div>
</template>

<script>
export default {
  name: 'AgentList'
}
</script> 